import React from 'react';
import oxxoLogo from '../../../assets/images/oxxo-logo.jpg';
import { Button, Modal, ModalBody, ModalHeader } from 'shards-react';
import alertIcon from '../../../assets/images/alert.png';
import '../../../assets/scss/PaymentsMethods.scss';
import { withRouter } from 'react-router-dom';
import { validatePaymentInputs } from '../../../custom/functions/authFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { sendData, addOxxo } from '../../../redux/payDucks';
const scrollToRef = (ref) => window.scrollTo({ top: 2000, behavior: 'smooth' });

const Oxxo = (props) => {
  // console.log('props de oxxo: ', props);
  const { paymentResponse } = props;
  const dispatch = useDispatch();
  const err = useSelector((store) => store.pay.error);
  const paymentIntent = useSelector((store) => store.pay.paymentIntent);
  const user = useSelector((state) => state.user.user);

  const myRef = React.useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  const [name, setName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [planId, setPlanId] = React.useState('');
  const [price, setPrice] = React.useState(0);
  const [error, setError] = React.useState('');
  const [planName, setPlanName] = React.useState('');
  const [open, setOpen] = React.useState(true);
  const [agree, setAgree] = React.useState(false);
  const tipoUsuario = props.plan.tipoUsuario;

  React.useEffect(() => {
    setName(user.name);
    setLastName(user.lastName);
    setEmail(user.email);
  }, [user]);

  React.useEffect(() => {
    executeScroll();
  }, []);

  React.useEffect(() => {
    setPlanId(props.plan.id);
    setPrice(props.plan.price);
    if (props.plan.id === 'USUARIO_ESTANDAR_MENSUAL') {
      setPlanName('month');
    } else if (props.plan.id === 'USUARIO_ESTANDAR_ANUAL') {
      setPlanName('year');
    }
  }, [props.plan.id]);

  React.useEffect(() => {
    if (err) {
      setError(err);
    }
    const sendPaymentToFirebase = () => {
      dispatch(addOxxo({ planName, paymentIntent, name, email, tipoUsuario }));
      paymentResponse({
        value: true,
        method: 'oxxo',
        error,
      });
    };
    if (paymentIntent) {
      sendPaymentToFirebase();
    }
  }, [paymentIntent]);

  const inputValues = {
    name: `${name} ${lastName}`,
    email: email,
    planId: planId,
    price: price,
  };

  const popup = () => {
    return (
      <Modal
        className='successModal text-center'
        open={open}
        centered={true}
        size='lg'
      >
        <ModalHeader>Aviso</ModalHeader>
        <ModalBody>
          <div>
            <img src={alertIcon} alt='Notification Icon' />
            <div className='container my-5'>
              <h4>
                Toma en cuenta que realizar tu pago a través de OXXO puede
                tardar de 1 a 3 días hábiles para verse reflejado y se cobra una
                comisión ajena a Vetlook. Además, no podras generar otro recibo
                o usar otro método de pago en este lapso.
              </h4>
            </div>
          </div>
          <Button className='btn btn-success' onClick={() => setOpen(!open)}>
            Continuar
          </Button>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <>
      <div className='pt-4' ref={myRef}>
        <div className='row'>
          <div className='col-12 col-lg-5 col-md-5 col-sm-12 col-xs-12'>
            <img className='oxxo_logo mb-4' src={oxxoLogo} alt='logo oxxo' />
          </div>
          <div className='col-12 col-lg-7 col-md-7 col-sm-12 col-xs-12'>
            <h4 className='py-4 text-center'>
              Costo de suscripción:
              <br />${props.plan.price}.00 {props.plan.period}
            </h4>
          </div>
        </div>
        {error && <div className='alert alert-danger'>{error}</div>}
        <form
          onSubmit={(e) =>
            validatePaymentInputs(e, inputValues, (res) => {
              if (res.valid === false) {
                setError(res.error);
              } else {
                dispatch(sendData(inputValues));
              }
            })
          }
        >
          <div className='form-group'>
            <label htmlFor='oxxoName'>Nombre</label>
            <input
              type='text'
              className='form-control'
              id='oxxoName'
              placeholder='Ingresa nombre y apellido'
              onChange={(e) => setName(e.target.value)}
              value={`${name} ${lastName}`}
            />
          </div>
          <div className='form-group'>
            <label htmlFor='oxxoEmail'>Correo Electrónico</label>
            <input
              type='email'
              className='form-control'
              id='oxxoEmail'
              placeholder='Ingresa tu correo'
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <small id='emailHelp' className='form-text text-muted'>
              Enviaremos un correo con el recibo para pagar en oxxo.
            </small>
          </div>
          <div className='form-group'>
            <label htmlFor='agree'>
              <input
                type='checkbox'
                name='agree'
                id='agree'
                onChange={() => setAgree(!agree)}
                value={agree}
              />{' '}
              Estoy enterada/o que debo esperar 3 dias hábiles para generar un
              nuevo recibo de OXXO o poder pagar con tarjeta.
            </label>
          </div>
          {agree && (
            <button
              type='submit'
              className='btn btn-primary btn-lg btn-block text-uppercase'
            >
              Enviar referencia de pago
            </button>
          )}
        </form>
        {popup()}
      </div>
    </>
  );
};

export default withRouter(Oxxo);
