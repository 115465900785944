import React from 'react';
import { withRouter } from 'react-router-dom';

const PlanCard = (props) => {
  const { getResponse } = props;
  return (
    <div className='card mb-5 mb-lg-0'>
      <div className='card-body'>
        <h5 className='card-title text-muted text-uppercase text-center'>
          {props.plan.name}
        </h5>
        <h6 className='card-price text-center'>
          ${props.plan.price}
          <span className='period'>.00 {props.plan.period}</span>
        </h6>
        <hr />
        <ul className='fa-ul'>
          <li>
            <span className='fa-li'>
              <i className='fas fa-check'></i>
            </span>
            Ubicación de servicios en tiempo real
          </li>
          <li>
            <span className='fa-li'>
              <i className='fas fa-check'></i>
            </span>
            Mascotas ilimitadas
          </li>
          <li>
            <span className='fa-li'>
              <i className='fas fa-check'></i>
            </span>
            Cartilla Digital
          </li>
          <li>
            <span className='fa-li'>
              <i className='fas fa-check'></i>
            </span>
            Agenda de citas
          </li>
          <li>
            <span className='fa-li'>
              <i className='fas fa-check'></i>
            </span>
            Enciclopedia
          </li>
          <li className='text-muted'>
            <span className='fa-li'>
              <i className='fas fa-check'></i>
            </span>
            Mascotas pérdidas*
          </li>
          <li className='text-muted'>
            <span className='fa-li'>
              <i className='fas fa-check'></i>
            </span>
            Reporte abuso animal*
          </li>
          <li className='text-muted'>
            <span className='fa-li'>
              <i className='fas fa-check'></i>
            </span>
            Adopción de mascotas*
          </li>
        </ul>
        {props.plan.member === true || props.plan.pending === true ? null : (
          <button
            type='button'
            className='btn btn-block btn-primary text-uppercase'
            onClick={() => {
              // console.log('el plan seleccionado es: ', {
              //   plan: props.plan.id,
              //   price: props.plan.price,
              //   period: props.plan.period,
              // });
              getResponse({
                plan: props.plan.id,
                price: props.plan.price,
                period: props.plan.period,
              });
            }}
          >
            Suscribirme
          </button>
        )}
      </div>{' '}
    </div>
  );
};

export default withRouter(PlanCard);
