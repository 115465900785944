import { db } from '../firebase';
import { pagaOtzo, mandaReciboOtso, cancelaMembresia } from '../api/api';
import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';

// constants: Valores iniciales para el state redux
const initialData = {
  payloading: false,
  paid: false,
};

// types: dispatchs que van a ejecutar el cambio de state en redux
const LOADING = 'LOADING';
const PAY_ERROR = 'PAY_ERROR';
const PAY_READY = 'PAY_READY';
const PAY_SUCCESS = 'PAY_SUCCESS';
const PAY_CANCELLED = 'PAY_CANCELLED';
const RESET_SUCCESS = 'RESET_SUCCESS';

// reducer: segun el dispatch que se ejecute, se cambiara el state
export default function payReducer(state = initialData, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, payloading: true };
    case PAY_ERROR:
      return { ...initialData, error: action.payload };
    case PAY_READY:
      return { ...state, paymentIntent: action.payload };
    case PAY_SUCCESS:
      return { ...state, paid: true };
    case PAY_CANCELLED:
      return { ...state, cancelled: true };
    case RESET_SUCCESS:
      return {
        ...initialData,
        message: 'Valores restaurados, por favor recargue la página.',
      };
    default:
      return { ...state };
  }
}

// actions
export const sendData = (values) => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  console.log(`Datos para pago en otzo: `, values);
  //mandar a pagar en otzo
  const pesitos = {
    pesitos: values.price,
  };
  pagaOtzo(pesitos).then(async (data) => {
    console.log('respuesta primer paso otzo', data.data);
    var secretKey = data.data.client_secret;
    // console.log('secretKey: ', secretKey);
    //ahora si lo shido xD
    const stripe = await loadStripe(
      'pk_live_51HI6rZLcOrOu0BMSC0y0y2W6TZdqPX8WLkR5GG7JfYUEI7owJ8zZxplknG8RHsW06SB6tPmjzsfJIfAoFN8tVxud00pM5nSGLT'
    );
    stripe
      .confirmOxxoPayment(secretKey, {
        payment_method: {
          billing_details: {
            name: values.name,
            email: values.email,
          },
        },
      }) // Stripe.js will open a modal to display the OXXO voucher to your customer
      .then(function(result) {
        console.log('Resultado antes de firebase: ', result.paymentIntent);
        localStorage.setItem(
          'oxxoPayment',
          JSON.stringify({
            amount: result.paymentIntent.amount,
            created: result.paymentIntent.created,
            currency: result.paymentIntent.currency,
            oxxoId: result.paymentIntent.id,
            reference:
              result.paymentIntent.next_action.oxxo_display_details.number,
          })
        );
        // This promise resolves when the customer closes the modal
        if (result.error) {
          // console.log('error oxxo: ', result.error);
          // var errorMsg = document.getElementById('error-message');
          // errorMsg.innerText = result.error.message;
          dispatch({
            type: PAY_ERROR,
            payload: result.error.message,
          });
        }
        dispatch({
          type: PAY_READY,
          payload: result.paymentIntent,
        });
      });
  });
};

export const addOxxo = (data) => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  if (localStorage.getItem('oxxoPayment')) {
    console.log('addOxxo data: ', data);
    try {
      const uid = JSON.parse(localStorage.getItem('uid'));
      db.collection('USUARIOS')
        .doc(uid)
        .set(
          {
            expiration_date: null,
            stripe: {
              id: data.paymentIntent.id,
              interval: data.planName,
              method: {
                option: 'oxxo',
                pending: true,
              },
              payment: data.paymentIntent,
            },
          },
          { merge: true }
        );
      console.log('se guardo en firebase');
      mandaReciboOtso({
        email: data.email,
        name: data.name,
        userID: uid,
        recibo:
          data.paymentIntent.next_action.oxxo_display_details
            .hosted_voucher_url,
        tipoUsuario: data.tipoUsuario,
      });
      console.log('se mando el correo alv');
    } catch (error) {
      dispatch({
        type: PAY_ERROR,
        payload: error,
      });
    }
  } else {
    console.log('no se ha enviado nada por oxxo');
  }
  dispatch({
    type: PAY_SUCCESS,
  });
};

export const cancellSubscription = (data) => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  try {
    const uid = JSON.parse(localStorage.getItem('uid'));
    console.log('la pinche suscripchon alv', data);
    cancelaMembresia({ subscriptionId: data.subscriptionId }).then(
      async (res) => {
        console.log('respuesta al cancelar: ', res);
        await db
          .collection('USUARIOS')
          .doc(uid)
          .set(
            {
              stripe: {
                cancel: {
                  cancel_date: moment().format('YYYYMMDDHHmmss'),
                  cancel_reason: data.reason,
                  cancel_comments: data.comments,
                  cancelled: true,
                },
              },
            },
            { merge: true }
          );
      }
    );
    const timer = setTimeout(() => {
      window.location.reload();
    }, 1000);
    return () => clearTimeout(timer);
  } catch (error) {
    console.log('error al cancelar subscripcion: ', error);
  }
};

export const resetValues = (data) => async (dispatch) => {
  try {
    const checkUserType = () => {
      if (data.typeUser === 'usuario') {
        return true;
      } else {
        return null;
      }
    };
    const uid = JSON.parse(localStorage.getItem('uid'));
    await db
      .collection('USUARIOS')
      .doc(uid)
      .update({
        expiration_date: null,
        freeMonth: checkUserType(),
        membership: false,
        stripe: {
          cancel: {
            cancelled: false,
            cancel_reason: null,
            cancel_comments: null,
            cancel_date: null,
          },
          id: null,
          interval: null,
          method: {
            option: null,
            pending: false,
          },
          payment: null,
        },
      });
    console.log('Valores restaurados');
    dispatch({
      type: RESET_SUCCESS,
    });
    window.location.reload();
  } catch (error) {
    console.log('error al reiniciar valores: ', error);
  }
};
