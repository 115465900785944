import React from 'react';
import { withRouter } from 'react-router-dom';
import '../../../assets/scss/signOut.scss';

import { useDispatch } from 'react-redux';
import { closeSession } from '../../../redux/userDucks';

const SignOut = () => {
  const dispatch = useDispatch();

  return (
    <div className='container'>
      <button
        id='btn-signOut'
        type='button'
        className='btn btn-danger btn-sm btn-signout'
        onClick={() => dispatch(closeSession())}
      >
        <span>
          <i className='material-icons'>power_settings_new</i> Cerrar Sesión
        </span>
      </button>
    </div>
  );
};

export default withRouter(SignOut);
