import React from 'react';
import { Container, Row, Col } from 'shards-react';

import PageTitle from '../components/common/PageTitle';
import UserDetails from '../components/user-profile-lite/UserDetails';
import UserAccountDetails from '../components/user-profile-lite/UserAccountDetails';

import { useSelector } from 'react-redux';

const UserProfileLite = () => {
  const doc = useSelector((store) => store.user.user);

  return (
    <Container fluid className='main-content-container px-4'>
      <Row noGutters className='page-header py-4'>
        <PageTitle
          title='Mi Cuenta'
          subtitle='Vetlook'
          md='12'
          className='ml-sm-auto mr-sm-auto'
        />
      </Row>
      <Row>
        <Col lg='4' md='4'>
          <UserDetails user={doc} />
        </Col>
        <Col lg='8' md='8'>
          <UserAccountDetails user={doc} />
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfileLite;
