import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Navbar } from 'shards-react';
import NavbarNav from './NavbarNav/NavbarNav';
import '../../../assets/scss/MobileMenu.scss';
import logoVetlook from '../../../assets/images/elemento-vetlook.png';

const MainNavbar = ({ layout, stickyTop }) => {
  const classes = classNames(
    'main-navbar',
    'bg-white',
    'mobile-menu',
    stickyTop && 'sticky-top'
  );

  return (
    <div className={classes}>
      <Container className='p-0'>
        <Navbar type='light' className='align-items-stretch flex-md-nowrap p-0'>
          <NavbarNav />
          <div className='w-50 py-1 text-center m-auto'>
            <img
              src={logoVetlook}
              alt='logo vetlook'
              style={{ width: 'auto', height: '3.2rem' }}
            />
            <h2 className='p-2'>Vetlook</h2>
          </div>
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
  stickyTop: true,
};

export default MainNavbar;
