import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand } from 'shards-react';
import elementoVetlook from '../../../assets/images/elemento-vetlook.png';

import { Dispatcher, Constants } from '../../../flux';

function SidebarMainNavbar(props) {
  const handleToggleSidebar = () => {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR,
    });
  };
// eslint-disable-next-line
  const { hideLogoText } = props;
  return (
    <div className='main-navbar'>
      <Navbar
        className='align-items-stretch bg-white flex-md-nowrap border-bottom p-0'
        type='light'
      >
        <NavbarBrand
          className='w-100 mr-0'
          href='/home'
          style={{ lineHeight: '2.5rem' }}
        >
          <div className='d-content m-auto'>
            <img
              id='main-logo'
              className='float-left pr-2 mt-1'
              style={{ maxWidth: '1.8rem' }}
              src={elementoVetlook}
              alt='Vetlook logo'
            />
            <h3 className='float-right'>Vetlook</h3>
          </div>
        </NavbarBrand>
        <button
          className='toggle-sidebar d-sm-inline d-md-none d-lg-none'
          onClick={handleToggleSidebar}
        >
          <i className='material-icons'>&#xE5C4;</i>
        </button>
      </Navbar>
    </div>
  );
}

SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false,
};

export default SidebarMainNavbar;
