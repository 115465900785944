import React, { useState } from 'react';
import { auth } from '../../firebase';

const Reset = () => {
	const [email, setEmail] = useState('');
	const [error, setError] = useState(null);
	const [notification, setNotification] = React.useState(null);

	const procesarDatos = (e) => {
		e.preventDefault();
		if (!email.trim()) {
			// console.log('Ingrese un correo válido');
			setError('Ingrese un correo válido');
			return;
		}
		console.log('Procesando validacion...');
		setError(null);
		recuperar();
	};

	const recuperar = React.useCallback(async () => {
		try {
			await auth.sendPasswordResetEmail(email);
			console.log('Correo enviado');
			setNotification('Si el usuario existe, recibirás un correo en breve.');
			setEmail('');
		} catch (err) {
			console.log(err);
			setError(err.message);
		}
	}, [email]);

	return (
		<div className='row justify-content-center'>
			<div className='col-12'>
				<form onSubmit={procesarDatos}>
					{error && <div className='alert alert-danger'>{error}</div>}
					{notification && <div className='alert alert-success'>{notification}</div>}
					<input
						type='email'
						className='form-control mb-2'
						placeholder='Ingrese su correo'
						onChange={(e) => setEmail(e.target.value)}
						value={email}
					/>
					<button className='btn btn-primary btn-md btn-block mt-3' type='submit'>
						Reestablecer contraseña
					</button>
				</form>
			</div>
		</div>
	);
};

export default Reset;
