import React from 'react';
import { Nav } from 'shards-react';
import SidebarNavItem from './SidebarNavItem';
import { Store } from '../../../flux';
import SignOut from './SignOut';

function SidebarNavItems() {
  const getState = () => {
    return {
      navItems: Store.getSidebarItems(),
    };
  };

  React.useEffect(() => {
    Store.addChangeListener(onChange);
    return () => {
      Store.removeChangeListener(onChange);
    };
  }, []);

  const onChange = () => {
    getState({
      ...getState,
      navItems: Store.getSidebarItems(),
    });
  };

  const { navItems: items } = getState();

  return (
    <div className='nav-wrapper'>
      <Nav className='nav--no-borders flex-column'>
        {items.map((item, idx) => (
          <SidebarNavItem key={idx} item={item} />
        ))}
        <li className='nav-item'>
          <a
            className='nav-link'
            href='https://vetlook.com.mx/facturacion/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='d-inline-block item-icon-wrapper'>
              <i className='material-icons'>receipt</i>
            </div>
            <span>¿Necesitas Factura?</span>
          </a>
          <SignOut />
        </li>
      </Nav>
    </div>
  );
}

export default SidebarNavItems;
