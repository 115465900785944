import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'shards-react';
import '../../assets/scss/Footer.scss';

const MainFooter = ({ contained, menuItems, year }) => (
  <footer className='main-footer footer p-2 px-3 bg-white border-top'>
    <Container fluid={contained}>
      <Row>
        <nav className='nav'>
          {menuItems.map((item, idx) => (
            <a
              href={item.to}
              className='nav-link'
              key={idx}
              target='_blank'
              rel='noopener noreferrer'
            >
              {item.title}
            </a>
          ))}
        </nav>
        <p className='copyright ml-auto my-auto mr-2'>
          <span>
            Copyright &copy; Vetlook {year} | Developed &amp; designed by:{' '}
            <a
              href='https://merakimx.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Meraki Mx
            </a>
          </span>
        </p>
      </Row>
    </Container>
  </footer>
);

MainFooter.propTypes = {
  /**
   * Whether the content is contained, or not.
   */
  contained: PropTypes.bool,
  /**
   * The menu items array.
   */
  menuItems: PropTypes.array,
  /**
   * The copyright year.
   */
  year: PropTypes.number,
};

MainFooter.defaultProps = {
  contained: false,
  year: 2021,
  menuItems: [
    {
      title: 'FAQs',
      to: 'https://vetlook.com.mx/faqs/',
    },
    {
      title: 'Aviso de privacidad',
      to: 'https://vetlook.com.mx/privacy/',
    },
    {
      title: 'Términos y condiciones',
      to: 'https://vetlook.com.mx/terms/',
    },
  ],
};

export default MainFooter;
