import React from 'react';
import '../../assets/scss/index.scss';
import LoginGoogleFacebook from './LoginGoogleFacebook';
import logo from '../../assets/images/logo-vetlook.png';
import Reset from './Reset';

import { validateLogin } from '../../custom/functions/authFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { loginWithEmail } from '../../redux/userDucks';

const Login = () => {
  const dispatch = useDispatch();
  const err = useSelector((store) => store.user.err);

  const [error, setError] = React.useState(null);
  const [email, setEmail] = React.useState('');
  const [pass, setPass] = React.useState('');
  const [resetPass, setResetPass] = React.useState(false);
  const isLogin = true;

  React.useEffect(() => {
    setError(err);
  }, [err]);

  const getForm = () => {
    if (isLogin && !resetPass) {
      return (
        <>
          {error && <div className='alert alert-danger'>{error}</div>}
          <form
            onSubmit={(e) =>
              validateLogin(e, { email, pass }, (res) => {
                if (res.valid === false) {
                  setError(res.error);
                } else {
                  dispatch(loginWithEmail(email, pass));
                }
              })
            }
          >
            <div className='form-group'>
              <input
                type='email'
                className='form-control'
                placeholder='Correo Electrónico'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
            <div className='form-group'>
              <input
                type='password'
                className='form-control'
                placeholder='Contraseña'
                onChange={(e) => setPass(e.target.value)}
                value={pass}
              />
            </div>
            <div className='form-group'>
              <button
                type='submit'
                className='btn btn-primary btn-lg btn-block'
              >
                Iniciar Sesión
              </button>
            </div>
          </form>
        </>
      );
    } else if (isLogin && resetPass === true) {
      return <Reset />;
    } else {
      return;
    }
  };

  const changeLoginTitle = () => {
    if (isLogin && !resetPass) {
      return 'Iniciar Sesión';
    } else if (isLogin && resetPass) {
      return 'Recuperar Contraseña';
    } else {
      return;
    }
  };

  const changeLinksTexts = () => {
    if (resetPass) {
      return 'Volver a iniciar sesión';
    } else {
      return '¿Olvidaste tu contarseña?';
    }
  };

  return (
    <div className='container login-container'>
      <div className='row form-box'>
        <div className='col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12 login-form-1'>
          <img className='login-logo' src={logo} alt='logo-vetlook' />
          <h3 className='py-3'>{changeLoginTitle()}</h3>
          {getForm()}
          <div className='login-container'>
            <div className='row'>
              <div className='col-12'>
                {!resetPass && (
                  <>
                    <hr className='separator' />
                    <div className='form-group text-center'>
                      <h5>Redes sociales disponibles para usuarios</h5>
                      <LoginGoogleFacebook />
                    </div>
                  </>
                )}
                <div className='form-group'>
                  {isLogin && (
                    <button
                      type='button'
                      className='btn btn-link btn-sm reset-link'
                      onClick={() => setResetPass(!resetPass)}
                    >
                      {changeLinksTexts()}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12 login-form-2'></div>
      </div>
    </div>
  );
};

export default Login;
