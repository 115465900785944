import React from 'react';
import '../../assets/scss/Login.scss';

import { useDispatch, useSelector } from 'react-redux';
import { googleLogin, facebookLogin } from '../../redux/userDucks';

const LoginGoogleFacebook = () => {
  const dispatch = useDispatch();
  const loading = useSelector((store) => store.user.loading);

  return (
    <div>
      <div className='row text-center'>
        <div className='py-2 col-12 col-lg-6 col-md-6 col-sm-12'>
          <input
            type='button'
            className='btn btn-block btn-social fbBtn'
            alt='button to login with facebook'
            onClick={() => dispatch(facebookLogin())}
            disabled={loading}
          />
        </div>
        <div className='py-2 col-12 col-lg-6 col-md-6 col-sm-12'>
          <input
            type='button'
            className='btn btn-block btn-social googleBtn'
            alt='button to login with google'
            onClick={() => dispatch(googleLogin())}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginGoogleFacebook;
