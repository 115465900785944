import React from 'react';
const scrollToRef = (ref) => window.scrollTo({ top: 1500, behavior: 'smooth' });

const PaymentForm = (props) => {
  const { getMethod } = props;
  const myRef = React.useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  React.useEffect(() => {
    executeScroll();
  }, []);

  return (
    <div className='row'>
      <div className='col-12'>
        <select
          ref={myRef}
          onChange={(e) => getMethod(e)}
          className='custom-select custom-select-lg mb-3'
        >
          <option defaultValue>Método de pago...</option>
          <option value='oxxo'>Oxxo</option>
          <option value='creditCard'>Tarjeta de Crédito/Débito</option>
        </select>
      </div>
    </div>
  );
};

export default PaymentForm;
