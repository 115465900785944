import axios from 'axios';
import { BASE_URL } from '../constant/constant';

export const pagaOtzo = (data) => {
  return axios
    .post(`${BASE_URL}/paymentIntent`, data)
    .then((response) => {
      // localStorage.setItem(STORAGE_NAME, JSON.stringify(response.data));
      // localStorage.setItem('x-access-token-expiration', Date.now() + 2 * 60 * 60 * 1000);
      return response.data;
    })
    .catch((err) => Promise.reject('otzo Fallo :( !'));
};

//mandar el recibo al usuario una ves que se haya creado el recibo de otso en stripe
export const cancelaMembresia = (data) => {
  console.log('data cancela membresia: ', data);
  return axios
    .post(`${BASE_URL}/cancel-subscription`, data)
    .then((response) => response.data)
    .catch((err) => Promise.reject('Request Not Authenticated!'));
};

//mandar el recibo al usuario una ves que se haya creado el recibo de otso en stripe
export const mandaReciboOtso = (data) => {
  return axios
    .post(`${BASE_URL}/mandaRecibo`, data)
    .then((response) => response.data)
    .catch((err) => Promise.reject('Request Not Authenticated!'));
};

//pago normal con la tarjeta
//crear usuario si no existe
export const createCustomer = (data) => {
  // var user = JSON.parse(localStorage.getItem(STORAGE_NAME));
  // axios.defaults.headers.common['x-access-token'] = user.token;
  return axios
    .post(`${BASE_URL}/create-customer`, data)
    .then((response) => response.data)
    .catch((err) => Promise.reject('Request Not Authenticated!'));
};

//agregar una subscripcion anual o mensual segun sea el caso
export const createSubscription = (data) => {
  // var user = JSON.parse(localStorage.getItem(STORAGE_NAME));
  // axios.defaults.headers.common['x-access-token'] = user.token;
  return axios
    .post(`${BASE_URL}/create-subscription`, data)
    .then((response) => response.data)
    .catch((err) => Promise.reject('Request Not Authenticated!'));
};

export const updateSubscription = (data) => {
  // var user = JSON.parse(localStorage.getItem(STORAGE_NAME));
  // axios.defaults.headers.common['x-access-token'] = user.token;
  return axios
    .post(`${BASE_URL}/update-subscription`, data)
    .then((response) => response.data)
    .catch((err) => Promise.reject('Request Not Authenticated!'));
};

///pagar solo una ves con stripe
export const payOnceStripe = (data) => {
	return axios
	  .post(`${BASE_URL}/payStripe`, data)
	  .then((response) => response.data)
	  .catch((err) => Promise.reject(err));
  };