// Layout Types
import { AdminLayout, LoginLayout } from './layouts';

// Route Views
import UserProfileLite from './views/UserProfileLite';
import Login from './components/auth/Login';
import Pricing from './components/payment/Pricing';

export default [
  {
    path: '/payment/login',
    layout: LoginLayout,
    component: Login,
  },
  {
    path: '/payment/pricing',
    layout: AdminLayout,
    component: Pricing,
  },
  {
    path: '/payment/my-account',
    layout: AdminLayout,
    component: UserProfileLite,
  },
];
