import { auth, db, firebase } from '../firebase';
import moment from 'moment';
import 'moment/locale/es-mx';

// constants;
const initialData = {
  loading: false,
  active: false,
};

// types;
const LOADING = 'LOADING';
const USER_ERROR = 'USER_ERROR';
const USER_SUCCESS = 'USER_SUCCESS';
const CLOSE_SESSION = 'CLOSE_SESSION';
const VALIDATED_USER = 'VALIDATED_USER';
const GET_USER_INFO = 'GET_USER_INFO';
const USER_UPDATED = 'USER_UPDATED';

// reducer;
export default function userReducer(state = initialData, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case USER_ERROR:
      return { ...initialData, err: action.payload };
    case USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        uid: action.payload.uid,
        active: true,
      };
    case CLOSE_SESSION:
      return { ...initialData };
    case VALIDATED_USER:
      return { ...state, token: action.payload, validated: true };
    case GET_USER_INFO:
      return {
        ...state,
        user: action.payload,
        active: true,
      };
    case USER_UPDATED:
      return {
        ...state,
        success: action.payload,
      };
    default:
      return { ...state };
  }
}

// actions;
export const loginWithEmail = (email, pass) => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  try {
    const res = await auth.signInWithEmailAndPassword(email, pass);
    const getAndDispatch = async () => {
      const doc = await db
        .collection('USUARIOS')
        .doc(res.user.uid)
        .get();
      const user = {
        address: doc.data().address,
        businessType: doc.data().businessType,
        cedula: doc.data().cedula,
        createdAt: doc.data().createdAt,
        dateBirth: doc.data().dateBirth,
        displayName: doc.data().displayName,
        email: doc.data().email,
        expiration_date: doc.data().expiration_date,
        freeMonth: doc.data().freeMonth,
        freeMonthExpiration: doc.data().freeMonthExpiration,
        lastName: doc.data().lastName,
        membership: doc.data().membership,
        membershipCancelled: doc.data().stripe.cancel.cancelled,
        cancelled_date: doc.data().stripe.cancel.cancel_date,
        name: doc.data().name,
        paymentMethod: doc.data().stripe.method.option,
        preSaleUser: doc.data().stripe.interval,
        pending: doc.data().stripe.method.pending,
        phoneNumber: doc.data().phoneNumber,
        photoURL: doc.data().photoURL,
        providerData: doc.data().providerData,
        responsibleDoctor: doc.data().responsibleDoctor,
        subscription_id: doc.data().subscription_id,
        telephoneContact: doc.data().telephoneContact,
        typeUser: doc.data().typeUser,
        uid: doc.data().uid,
        verifiedCedula: doc.data().verifiedCedula,
        zipCode: doc.data().zipCode,
      };
      dispatch({
        type: USER_SUCCESS,
        payload: {
          uid: res.user.uid,
          user: user,
        },
      });
    };
    getAndDispatch();

    localStorage.setItem('uid', JSON.stringify(res.user.uid));
  } catch (error) {
    console.log(error);
    if (
      error.code === 'auth/user-not-found' ||
      error.code === 'auth/invalid-email'
    ) {
      dispatch({
        type: USER_ERROR,
        payload: 'No se encontró el usuario',
      });
    }
    if (error.code === 'auth/wrong-password') {
      dispatch({
        type: USER_ERROR,
        payload: 'Las credenciales no coinciden',
      });
    }
    if (error.code === 'auth/too-many-requests') {
      dispatch({
        type: USER_ERROR,
        payload:
          'Demasiados intentos: se ha bloqueado el acceso temporalmente, pruebe más tarde',
      });
    }
  }
};

export const googleLogin = () => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  try {
    const provider = new firebase.auth.GoogleAuthProvider();
    const res = await auth.signInWithPopup(provider);
    // console.log('res google: ', res);

    const getAndDispatch = async () => {
      const doc = await db
        .collection('USUARIOS')
        .doc(res.user.uid)
        .get();
      const user = {
        address: doc.data().address,
        businessType: doc.data().businessType,
        cedula: doc.data().cedula,
        createdAt: doc.data().createdAt,
        dateBirth: doc.data().dateBirth,
        displayName: doc.data().displayName,
        email: doc.data().email,
        expiration_date: doc.data().expiration_date,
        freeMonth: doc.data().freeMonth,
        freeMonthExpiration: doc.data().freeMonthExpiration,
        lastName: doc.data().lastName,
        membership: doc.data().membership,
        membershipCancelled: doc.data().stripe.cancel.cancelled,
        cancelled_date: doc.data().stripe.cancel.cancel_date,
        name: doc.data().name,
        paymentMethod: doc.data().stripe.method.option,
        preSaleUser: doc.data().stripe.interval,
        pending: doc.data().stripe.method.pending,
        phoneNumber: doc.data().phoneNumber,
        photoURL: doc.data().photoURL,
        providerData: doc.data().providerData,
        responsibleDoctor: doc.data().responsibleDoctor,
        subscription_id: doc.data().subscription_id,
        telephoneContact: doc.data().telephoneContact,
        typeUser: doc.data().typeUser,
        uid: doc.data().uid,
        verifiedCedula: doc.data().verifiedCedula,
        zipCode: doc.data().zipCode,
      };
      // console.log('user document: ', user);
      dispatch({
        type: USER_SUCCESS,
        payload: {
          uid: res.user.uid,
          user: user,
        },
      });
    };

    getAndDispatch();

    localStorage.setItem('uid', JSON.stringify(res.user.uid));
    // console.log('uid saved: ', res.user.uid);
  } catch (error) {
    console.log(error);
  }
};

export const facebookLogin = () => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  try {
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope('email');
    const res = await auth.signInWithPopup(provider);
    // console.log('desde facebook: ', res);

    const getAndDispatch = async () => {
      const doc = await db
        .collection('USUARIOS')
        .doc(res.user.uid)
        .get();
      const user = {
        address: doc.data().address,
        businessType: doc.data().businessType,
        cedula: doc.data().cedula,
        createdAt: doc.data().createdAt,
        dateBirth: doc.data().dateBirth,
        displayName: doc.data().displayName,
        email: doc.data().email,
        expiration_date: doc.data().expiration_date,
        freeMonth: doc.data().freeMonth,
        freeMonthExpiration: doc.data().freeMonthExpiration,
        lastName: doc.data().lastName,
        membership: doc.data().membership,
        membershipCancelled: doc.data().stripe.cancel.cancelled,
        cancelled_date: doc.data().stripe.cancel.cancel_date,
        name: doc.data().name,
        paymentMethod: doc.data().stripe.method.option,
        preSaleUser: doc.data().stripe.interval,
        pending: doc.data().stripe.method.pending,
        phoneNumber: doc.data().phoneNumber,
        photoURL: doc.data().photoURL,
        providerData: doc.data().providerData,
        responsibleDoctor: doc.data().responsibleDoctor,
        subscription_id: doc.data().subscription_id,
        telephoneContact: doc.data().telephoneContact,
        typeUser: doc.data().typeUser,
        uid: doc.data().uid,
        verifiedCedula: doc.data().verifiedCedula,
        zipCode: doc.data().zipCode,
      };
      dispatch({
        type: USER_SUCCESS,
        payload: {
          uid: res.user.uid,
          user: user,
        },
      });
    };

    getAndDispatch();
    localStorage.setItem('uid', JSON.stringify(res.user.uid));
    // console.log('uid saved: ', res.user.uid);
  } catch (error) {
    console.log(error);
  }
};

export const checkActiveUser = () => async (dispatch) => {
  const uid = localStorage.getItem('uid');
  if (uid) {
    dispatch({
      type: USER_SUCCESS,
      payload: JSON.parse(uid),
    });
  }
};

export const getUserData = () => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  try {
    const uid = JSON.parse(localStorage.getItem('uid'));
    const doc = await db
      .collection('USUARIOS')
      .doc(uid)
      .get();

    const user = {
      address: doc.data().address,
      businessType: doc.data().businessType,
      cedula: doc.data().cedula,
      createdAt: doc.data().createdAt,
      dateBirth: doc.data().dateBirth,
      displayName: doc.data().displayName,
      email: doc.data().email,
      expiration_date: doc.data().expiration_date,
      freeMonth: doc.data().freeMonth,
      freeMonthExpiration: doc.data().freeMonthExpiration,
      lastName: doc.data().lastName,
      membership: doc.data().membership,
      membershipCancelled: doc.data().stripe.cancel.cancelled,
      cancelled_date: doc.data().stripe.cancel.cancel_date,
      name: doc.data().name,
      paymentMethod: doc.data().stripe.method.option,
      preSaleUser: doc.data().stripe.interval,
      pending: doc.data().stripe.method.pending,
      phoneNumber: doc.data().phoneNumber,
      photoURL: doc.data().photoURL,
      providerData: doc.data().providerData,
      responsibleDoctor: doc.data().responsibleDoctor,
      subscription_id: doc.data().subscription_id,
      telephoneContact: doc.data().telephoneContact,
      typeUser: doc.data().typeUser,
      uid: doc.data().uid,
      verifiedCedula: doc.data().verifiedCedula,
      zipCode: doc.data().zipCode,
    };
    // console.log('usuario: ', user);
    dispatch({
      type: USER_SUCCESS,
      payload: {
        uid: uid,
        user: user,
      },
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: USER_ERROR,
    });
  }
};

export const validateEmail = () => async (dispatch) => {
  const url = window.location.search;
  const urlParam = new URLSearchParams(url);
  const token = urlParam.get('token');

  if (token !== null) {
    try {
      await db
        .collection('USUARIOS')
        .doc(token)
        .update({
          verifiedAccount: true,
        });
      // console.log('Gracias por confirmar su cuenta');
      dispatch({
        type: VALIDATED_USER,
        payload: token,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: USER_ERROR,
      });
    }
  }
};

export const closeSession = () => (dispatch) => {
  dispatch({
    type: CLOSE_SESSION,
  });
  setTimeout(() => {
    auth.signOut();
    localStorage.clear();
    window.location.reload();
  }, 500);
};

export const updateData = (data) => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  const uid = JSON.parse(localStorage.getItem('uid'));
  const validateBirth = () => {
    if (
      isNaN(data.dateBirth) ||
      data.dateBirth === null ||
      data.dateBirth === undefined
    ) {
      return '';
    } else {
      return moment(data.dateBirth).format('YYYY-MM-DD');
    }
  };
  // console.log('info enviada para actualizar: ', data);
  try {
    await db
      .collection('USUARIOS')
      .doc(uid)
      .update({
        address: data.address,
        dateBirth: validateBirth(),
        displayName: `${data.name} ${data.lastName}`,
        fullName: `${data.name} ${data.lastName}`,
        lastName: data.lastName,
        name: data.name,
        phoneNumber: data.phone,
        responsibleDoctor: `${data.name} ${data.lastName}`,
        telephoneContact: data.telephoneContact,
        zipCode: data.zipCode,
      });
    dispatch({
      type: USER_UPDATED,
      payload: true,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: USER_ERROR,
    });
  }
};
