export default function() {
  return [
    // {
    //   title: "Inicio",
    //   to: "/home",
    //   htmlBefore: '<i className="material-icons">home</i>',
    //   htmlAfter: ""
    // },
    {
      title: 'Membresías',
      htmlBefore: '<i class="material-icons">store</i>',
      to: '/payment/pricing',
    },
    // {
    //   title: "Add New Post",
    //   htmlBefore: '<i className="material-icons">note_add</i>',
    //   to: "/add-new-post"
    // },
    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i className="material-icons">view_module</i>',
    //   to: "/components-overview"
    // },
    // {
    //   title: "Tables",
    //   htmlBefore: '<i className="material-icons">table_chart</i>',
    //   to: "/tables"
    // },
    {
      title: 'Mi Cuenta',
      htmlBefore: '<i class="material-icons">person</i>',
      to: '/payment/my-account',
    },
    // {
    // 	title: 'Facturación',
    // 	htmlBefore: '<i className="material-icons">receipt</i>',
    // 	to: 'https://vetlook.com.mx/facturacion/',
    // },
  ];
}
