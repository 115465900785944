import React from 'react';
import { withRouter } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { db } from '../../../firebase';
import moment from 'moment';
import 'moment/locale/es-mx';
import { createCustomer, payOnceStripe } from '../../../api/api';
import card_logo from '../../../assets/images/credit-card.png';

const scrollToRef = (ref) => window.scrollTo({ top: 2000, behavior: 'smooth' });
//llave para la conexion con la cuenta de stripe
const stripeKey =
  'pk_live_51HI6rZLcOrOu0BMSC0y0y2W6TZdqPX8WLkR5GG7JfYUEI7owJ8zZxplknG8RHsW06SB6tPmjzsfJIfAoFN8tVxud00pM5nSGLT';
const stripePromise = loadStripe(stripeKey);

const CheckoutForm = (props) => {
  // console.log('props de tarjeta: ', props);
  const myRef = React.useRef(null);
  const executeScroll = () => scrollToRef(myRef);

  React.useEffect(() => {
    executeScroll();
    console.log(props);
  }, []);

  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = React.useState('');
  const [cardData, setCardData] = React.useState({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    zipCode: '',
    email: props.plan.email,
    price: props.plan.price,
    id: props.plan.id,
  });

  const name = (e) => {
    setError('');
    setCardData({ ...cardData, name: e.target.value });
  };
  const email = (e) => {
    setError('');
    setCardData({ ...cardData, email: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log('event: ', event);
    if (!cardData.name.trim()) {
      // console.log('Ingresa el nommbre del titular de la tarjeta');
      setError('Ingresa el nombre del titular de la tarjeta');
      return false;
    }
    if (!cardData.email.trim()) {
      // console.log('Ingresa el nommbre del titular de la tarjeta');
      setError('Ingresa un correo valido');
      return false;
    }
// eslint-disable-next-line
    var elemntos = elements.getElement(CardElement);
    // console.log('elemntos: ', elemntos);
    //CREAR METODO DE PAGO

    if (props.plan.id === 'CLIENTE_ANUAL_PREVENTA') {
      //////////////////////////  esto solo se usara cuando se pague una sola vez  /////////////////////////
      // eslint-disable-next-line
      const stripePayOnce = await stripe
        .createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            email: cardData.email,
            name: cardData.name,
          },
        })
        .then((response) => {
          console.log('createPaymentMethod: ', response);

          const objetoPago = {
            email: cardData.email,
            fullName: cardData.name,
            type: 'card',
            paymentMethodId: response.paymentMethod.id,
            amount: props.plan.price, //cambiar el monto que ingresara la persona
            currency: 'MXN',
          };

          console.log('objetoPago: ', objetoPago);
          payOnceStripe(objetoPago).then((res) => {
            console.log('esta es la respuesta de pago con stripe: ', res);
            savePayment(res);
          });
        });

      // return false; ///quitarlo cuando ya se le mueva
      //////////////////////////  esto solo se usara cuando se pague una sola vez  /////////////////////////
    } else {
      ///// aqui empieza lo de la suscriocion
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          email: cardData.email,
          name: cardData.name,
        },
      });

      console.log('paymentMethod: ', paymentMethod);
      console.log('error: ', error);
      console.log('planid', props.plan.id);
      if (error === undefined) {
        const { id } = paymentMethod;
        var form = new FormData();
        form.append('email', cardData.email);
        form.append('payment_method', id);
        form.append('planid', props.plan.id);

        const obj = {
          email: cardData.email,
          payment_method: id,
          planid: props.plan.id,
        };

        createCustomer(obj).then((res) => {
          console.log('respuesta: ', res);

          //agregando los ultimos pasos
          const { latest_invoice } = res;
          // eslint-disable-next-line
          const { payment_intent } = latest_invoice;

          savePayment(res);
        });
      }
    } //end if no error

    const savePayment = (res) => {
      console.log('respuesta tdc: ', res);
      try {
        // funcion que revisa si el plan es anual o mensual y agrega año o mes a la fecha actual
        const expirationDate = () => {
          // esta propiedad viene de los props en formato "MXN/mensual" y "MXN/anual"
          const period = props.plan.period;
          if (period.includes('anual')) {
            return moment()
              .add(1, 'y')
              .format('YYYYMMDD');
          } else if (period.includes('mensual')) {
            return moment()
              .add(1, 'M')
              .format('YYYYMMDD');
          } else {
            return null;
          }
        };
        // elimina el mes gratis en caso de ser usuario, si es cliente lo manda null
        const removeFreeMonth = () => {
          const userType = props.plan.typeUser;
          if (userType === 'negocio') {
            return null;
          } else if (userType === 'usuario') {
            return false;
          } else {
            return null;
          }
        };
        const uid = JSON.parse(localStorage.getItem('uid'));
        // datos que se actualizan en firebase al pagar
        const stripeResponse = () => {
          if (props.plan.id === 'CLIENTE_ANUAL_PREVENTA') {
            return {
              id: res.id,
              interval: props.plan.id,
              method: {
                option: 'tarjeta',
                pending: false,
              },
              payment: res.charges.data[0],
            };
          } else {
            return {
              id: res.plan.id,
              interval: res.plan.interval,
              method: {
                option: 'tarjeta',
                pending: false,
              },
              payment: res.plan,
            };
          }
        };
        const addPayment = {
          freeMonth: removeFreeMonth(),
          membership: true,
          expiration_date: expirationDate(),
          subscription_id: res.id,
          stripe: stripeResponse(),
        };
        db.collection('USUARIOS')
          .doc(uid)
          .set(addPayment, { merge: true });
// eslint-disable-next-line
        const history = db
          .collection('PAYMENT_HISTORY')
          .doc(uid)
          .get();
          // eslint-disable-next-line
        const addToHistory = {
          amount: props.plan.price,
        };
        // console.log('se agrego el pago al usuario: ', addPayment);
        localStorage.setItem('paymentSuccess', true);
        finishPayment();
      } catch (error) {
        console.log(error);
        finishPayment(error);
      }
    };
// eslint-disable-next-line
    const orderComplete = (subscription) => {};

    // 	try {
    // 	  const { data } = await axios.post("/api/charge", { id, amount: 1099 });
    // 	  console.log(data);
    // 	  success();
    // 	} catch (error) {
    // 	  console.log(error);
    // 	}
    //   }

    const finishPayment = (error) => {
      props.onFinish(true, error);
    };
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <div ref={myRef}>
      <div className='row'>
        <div className='col-12 col-lg-5 col-md-5 col-sm-12 col-xs-12'>
          <img
            style={{ width: '16rem' }}
            src={card_logo}
            alt='credit card icon'
          />
        </div>
        <div className='col-12 col-lg-7 col-md-7 col-sm-12 col-xs-12'>
          <h4 className='py-5 text-center'>
            Costo de suscripción:
            <br />${props.plan.price}.00 {props.plan.period}
          </h4>
        </div>
      </div>
      <form id='PaymentForm' onSubmit={handleSubmit} className='pt-4'>
        {error && <div className='alert alert-danger'>{error}</div>}
        <div className='row form-group pt-4'>
          <div className='form-group col-12 col-lg-6 col-md-6 sol-sm-12'>
            <label>Nombre completo</label>
            <input
              type='text'
              className='form-control'
              name='name'
              placeholder='Tu nombre como aparece en tu tarjeta'
              onChange={name}
              value={cardData.name}
            />
          </div>
          <div className='form-group col-12 col-lg-6 col-md-6 sol-sm-12'>
            <label>Correo</label>
            <input
              type='email'
              className='form-control'
              name='email'
              placeholder='Correo'
              onChange={email}
              value={cardData.email}
            />
          </div>
        </div>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
        <br></br>
        <button type='submit' className='btn btn-primary btn-lg btn-block'>
          Pagar
        </button>
      </form>
    </div>
  );
};

const CreditDebitCard = (props) => {
  const { paymentResponse } = props;

  const onFinish = (value, error) => {
    paymentResponse({
      value,
      method: 'card',
      error,
    });
  };

  return (
    <div>
      <Elements stripe={stripePromise}>
        <CheckoutForm onFinish={onFinish} {...props} />
      </Elements>
    </div>
  );
};

export default withRouter(CreditDebitCard);
