import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'shards-react';
import MainSidebar from '../components/layout/MainSidebar/MainSidebar';
import MainFooter from '../components/layout/MainFooter';
import MainNavbar from '../components/layout/MainNavbar/MainNavbar';

const AdminLayout = (props) => {
  return (
    <>
      <Container fluid style={{ height: '100vh' }}>
        <Row>
          <MainSidebar />
          <Col
            className='main-content p-0'
            lg={{ size: 10, offset: 2 }}
            md={{ size: 9, offset: 3 }}
            sm='12'
            tag='main'
            style={{ height: '100vh' }}
          >
            {!props.noNavbar && <MainNavbar />}
            {props.children}
            {!props.noFooter && <MainFooter />}
          </Col>
        </Row>
      </Container>
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n    .main-content > .main-content-container.container-fluid {\n    min-height: calc(100vh - 4rem);\n}\n    ',
        }}
      />
    </>
  );
};

AdminLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

AdminLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default AdminLayout;
