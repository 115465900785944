export function validNumber(value, callback = () => {}) {
  // let res = null;
  const regex = /^[0-9\b]+$/;
  if (value === '' || regex.test(value)) {
    // console.log('si es numero');
    callback({ value, error: '' });
  } else {
    // console.log('no es numero');
    callback({ value: '', error: 'Solo se admiten números' });
  }
}

export function capitalizeData(value, callback = () => {}) {
  const word = value.toLowerCase().replace(/\b[a-z]/g, function(letter) {
    return letter.toUpperCase();
  });
  callback(word);
}

// funcion que valida si se llenaron todos los campos al registrar nuevo usuario
export function validateInputs(e, data, callback = () => {}) {
  e.preventDefault();
  let error = '';

  if (!data.name.trim() && data.isLogin === false) {
    // console.log('Nombre(s) requerido(s)');
    error = 'Nombre(s) requerido(s)';
    callback({ valid: false, error });
    return;
  }
  if (!data.lastName.trim() && data.isLogin === false) {
    // console.log('Apellido requerido');
    error = 'Apellido requerido';
    callback({ valid: false, error });
    return;
  }
  if (!data.email.trim() && data.isLogin === false) {
    // console.log('Ingresa un correo válido');
    error = 'Ingresa un correo válido';
    callback({ valid: false, error });
    return;
  }
  if (!data.phoneNumber.trim() && data.isLogin === false) {
    // console.log('Teléfono requerido');
    error = 'Teléfono requerido';
    callback({ valid: false, error });
    return;
  }
  if (!data.zipCode.trim() && data.isLogin === false) {
    error = 'Ingresa un código postal válido';
    callback({ valid: false, error });
    return;
  }
  if (!data.pass.trim() && data.isLogin === false) {
    error = 'La contraseña debe tener mínimo 8 caractéres';
    callback({ valid: false, error });
    return;
  }
  if (data.pass.length < 8 && data.isLogin === false) {
    error = 'La contraseña debe tener mínimo 8 caractéres';
    callback({ valid: false, error });
    return;
  }
  if (data.pass !== data.passConfirmed && data.isLogin === false) {
    error = 'Las contraseñas no coinciden';
    callback({ valid: false, error });
    return;
  }
  callback({ valid: true, error: '' });
  return;
}
// funcion que valida si se llenaron todos los campos al registrar nuevo cliente
export function validateInputsClient(e, data, callback = () => {}) {
  e.preventDefault();
  let error = '';

  if (!data.name.trim() && data.isLogin === false) {
    error = 'Nombre(s) requerido(s)';
    callback({ valid: false, error });
    return;
  }
  if (!data.lastName.trim() && data.isLogin === false) {
    error = 'Apellido requerido';
    callback({ valid: false, error });
    return;
  }
  if (!data.phoneNumber.trim() && data.isLogin === false) {
    // console.log('Teléfono requerido');
    error = 'Teléfono requerido';
    callback({ valid: false, error });
    return;
  }
  if (!data.address.trim() && data.isLogin === false) {
    error = 'Dirección requerida';
    callback({ valid: false, error });
    return;
  }
  if (!data.telephoneContact.trim() && data.isLogin === false) {
    error = 'Teléfono de contacto requerido';
    callback({ valid: false, error });
    return;
  }
  if (!data.zipCode.trim() && data.isLogin === false) {
    error = 'Ingresa un código postal válido';
    callback({ valid: false, error });
    return;
  }
  if (!data.email.trim() && data.isLogin === false) {
    error = 'Ingresa un correo válido';
    callback({ valid: false, error });
    return;
  }
  if (!data.pass.trim() && data.isLogin === false) {
    error = 'La contraseña debe tener mínimo 8 caractéres';
    callback({ valid: false, error });
    return;
  }
  if (data.pass.length < 8 && data.isLogin === false) {
    error = 'La contraseña debe tener mínimo 8 caractéres';
    callback({ valid: false, error });
    return;
  }
  if (data.pass !== data.passConfirmed && data.isLogin === false) {
    error = 'Las contraseñas no coinciden';
    callback({ valid: false, error });
    return;
  }
  callback({ valid: true, error: '' });
  return;
}
// funcion que valida si se llenaron todos los campos al actualizar datos en "mi cuenta"
export function validateAccountInputs(e, data, callback = () => {}) {
  e.preventDefault();
  let error = '';
  // console.log('data para actualizar: ', data);

  if (!data.name.trim()) {
    error = 'Nombre(s) requerido(s)';
    callback({ valid: false, error });
    return;
  }
  if (!data.lastName.trim()) {
    error = 'Apellido requerido';
    callback({ valid: false, error });
    return;
  }
  if (!data.phone.trim()) {
    error = 'Teléfono requerido';
    callback({ valid: false, error });
    return;
  }
  if (!data.address.trim()) {
    error = 'Ingresa tu dirección';
    callback({ valid: false, error });
    return;
  }
  if (!data.zipCode.trim()) {
    error = 'Ingresa un código postal válido';
    callback({ valid: false, error });
    return;
  }
  if (data.businessType) {
    if (!data.telephoneContact.trim()) {
      error = 'Teléfono de negocio requerido';
      callback({ valid: false, error });
      return;
    }
  }
  callback({ valid: true, error: '' });
  return;
}
// funcion que valida que se hayan llenado todos los campos al iniciar sesion en login
export function validateLogin(e, data, callback = () => {}) {
  e.preventDefault();
  let error = '';

  if (!data.email.trim() || !data.pass.trim()) {
    // console.log('Ingresa un correo válido');
    error = 'Todos los campos son obligatorios';
    callback({ valid: false, error });
  }

  callback({ valid: true, error: '' });
}
// funcion que valida si se llenaron los campos del form al momento de pagar
export function validatePaymentInputs(e, data, callback = () => {}) {
  e.preventDefault();
  let error = '';

  if (!data.name.trim()) {
    error = 'El campo de nombre no puede estar vacío';
    callback({ valid: false, error });
    return;
  }
  if (!data.email.trim()) {
    error = 'El campo de correo electrónico es obligatorio';
    callback({ valid: false, error });
    return;
  }
  callback({ valid: true, error: '' });
  return;
}
