import React from 'react';
import Login from '../components/auth/Login';
import '../assets/scss/index.scss';
import Alerts from '../components/Alerts/Alerts';
import { useSelector } from 'react-redux';

/* EXAMPLE USER:
localhost:3000/login?token=ro85YSXq8vMNoOFwQGKKbziYlAg1
*/

const LoginLayout = () => {
  const [open, setOpen] = React.useState(false);
  const validated = useSelector((store) => store.user.validated);
  // console.log('validated: ', validated);

  React.useEffect(() => {
    if (validated === true) {
      setOpen(true);
    }
  }, [validated]);

  return (
    <section className='login-layout'>
      <Alerts
        open={open}
        close={() => {
          setOpen(!open);
        }}
        message='Gracias, hemos verificado tu cuenta éxitosamente.'
        theme='success'
      />
      <Login />
    </section>
  );
};

export default LoginLayout;
