import React from 'react';
import { NavItem, NavLink, Collapse, DropdownItem } from 'shards-react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeSession } from '../../../../redux/userDucks';

export default function MobileMenu() {
  const dispatch = useDispatch();

  const [visible, setVisible] = React.useState(false);

  const toggleNotifications = () => {
    setVisible(!visible);
  };

  return (
    <NavItem className='border-right dropdown notifications'>
      <NavLink
        className='nav-link-icon text-center'
        onClick={toggleNotifications}
      >
        <div className='nav-link-icon__wrapper'>
          <i className='material-icons'>menu</i>
        </div>
      </NavLink>
      <Collapse open={visible} className='dropdown-menu dropdown-menu-small'>
        <DropdownItem>
          <div className='row pl-3'>
            <div className='col-12'>
              <Link className='nav-link' to='/payment/pricing'>
                <h6>
                  <i class='material-icons'>store</i>
                  Membresías
                </h6>
              </Link>
            </div>
            <div className='col-12'>
              <Link className='nav-link' to='/payment/my-account'>
                <h6>
                  <i class='material-icons'>person</i>
                  Mi Cuenta
                </h6>
              </Link>
            </div>
            <div className='col-12'>
              <a
                className='nav-link'
                href='https://vetlook.com.mx/facturacion/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <h6>
                  <i class='material-icons'>receipt</i>
                  ¿Necesitas factura?
                </h6>
              </a>
            </div>
          </div>
        </DropdownItem>
        <DropdownItem className='notification__all text-center'>
          <div className='container'>
            <button
              className='btn btn-danger btn-sm'
              onClick={() => dispatch(closeSession())}
            >
              Cerrar Sesión
            </button>
          </div>
        </DropdownItem>
      </Collapse>
    </NavItem>
  );
}
