import React from 'react';
import '../../assets/scss/Pricing.scss';
import PlanCard from '../plans/PlanCard';
import PlanCardClients from '../plans/PlanCardClients';
import PaymentForm from '../payment/PaymentForm';
import CreditDebitCard from './paymentMethods/CreditDebitCard';
import Oxxo from './paymentMethods/Oxxo';
import Popup from '../Modal/Popup';
import moment from 'moment';
import 'moment/locale/es-mx';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Pricing = () => {
  const myRef = React.useRef(null);

  const userRedux = useSelector((store) => store.user.user);

  const [showForm, setShowForm] = React.useState(false);
  const [method, setMethod] = React.useState('');
  const [user, setUser] = React.useState({});
  const [plan, setPlan] = React.useState({});
  const [clientPrice, setClientPrice] = React.useState(0);
  const [clientPlanName, setClientPlanName] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState({});

  const today = moment();
  const endPreSale = moment("20210815", "YYYYMMDD");

  React.useEffect(() => {
    const fetchData = async () => {
      setUser(userRedux);
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    const validatePreSaleDate = () => {
      if (today <= endPreSale) {
        setClientPrice(1000);
        setClientPlanName('CLIENTE_ANUAL_PREVENTA');
      } else {
        setClientPrice(1000);
        setClientPlanName('CLIENTE_ESTANDAR_ANUAL');
      }
    };
    validatePreSaleDate();
  }, []);

  const planTitle = () => {
    if (user.membership) {
      if (user.interval === 'month') {
        return (
          <p>
            Hola {user.name}, actualmente cuentas con el plan{' '}
            <strong className='text-success'>ESTANDAR MENSUAL</strong>.<br />
            Gracias por usar{' '}
            <strong className='secondary-color'>Vetlook</strong>.
          </p>
        );
      } else if (user.interval === 'year') {
        return (
          <p>
            Hola {user.name}, actualmente cuentas con el plan{' '}
            <strong className='text-success'>ESTANDAR ANUAL</strong>.<br />
            Gracias por usar{' '}
            <strong className='secondary-color'>Vetlook</strong>.
          </p>
        );
      }
    } else if (user.freeMonth && user.typeUser === 'usuario') {
      return (
        <p>
          Hola {user.name}, actualmente cuentas con{' '}
          <strong className='text-success'>30 días gratis</strong>.<br />Y la
          fecha de vencimiento es el{' '}
          {moment(user.freeMonthExpiration).format(
            'dddd DD [de] MMMM [del] YYYY'
          )}
          .
          <br />
          {user.pending ? (
            <>
              Tu pago con <strong className='bg-warning text-dark'>Oxxo</strong>{' '}
              sigue pendiente de proceso.
            </>
          ) : (
            'Considera adquirir uno de los siguientes planes:'
          )}
        </p>
      );
    } else {
      return (
        <p>
          ¡Hola <strong className='text-success'>{user.name}</strong>!<br />
          {user.typeUser === 'usuario' && (
            <>
              Tu mes gratis finaliza el{' '}
              {moment(user.freeMonthExpiration).format(
                'dddd DD [de] MMMM [del] YYYY'
              )}
              .
            </>
          )}{' '}
          Gracias por confiar en{' '}
          <strong className='secondary-color'>Vetlook</strong>.{' '}
          {user.pending ? (
            <>
              Tu pago con <strong className='bg-warning text-dark'>Oxxo</strong>{' '}
              sigue pendiente de proceso.
            </>
          ) : (
            'Elige el plan que más te convenga.'
          )}
          {clientPrice === 1800 && user.typeUser === 'negocio' && (
            <p>
              <strong>
                Preventa Plan Estandar Anual{' '}
                <span className='text-success'>$1,000</span>
              </strong>
              , finaliza hasta el{' '}
              {endPreSale.format('dddd DD [de] MMMM [del] YYYY')}
            </p>
          )}
        </p>
      );
    }
  };

  const getResponse = (data) => {
    setShowForm(true);
    setPlan(data);
  };

  const getMethod = (e) => {
    setMethod(e.target.value);
  };

  const paymentResponse = (res) => {
    // console.log('respuesta al cerrar popup: ', res);
    if (res.error) {
      if (res.method === 'oxxo') {
        setMessage({
          title: 'Ups, Algo Anda Mal',
          body:
            'Lo sentimos, parece que hubo un problema desde Oxxo, puedes intentar de nuevo o con otro método, si el problema persiste contáctanos.',
          btn: 'Cerrar',
          openApp: false,
        });
        setOpen(res.value);
      } else {
        setMessage({
          title: 'Ups, Algo Anda Mal',
          body:
            'Lo sentimos, parece que hubo un problema con el pago, prueba de nuevo intenta otro método, si el problema persiste contáctanos.',
          btn: 'Cerrar',
          openApp: false,
        });
        setOpen(res.value);
      }
    } else {
      if (res.method === 'oxxo') {
        setMessage({
          title: 'Solicitud enviada con éxito',
          body: `¡Casi listo! Cuentas con 3 días a partir de hoy para realizar el pago en tiendas Oxxo. Una vez realizado el pago envía una copia de tu recibo al correo soporte@vetlook.com.mx para agilizar tu registro.`,
          btn: 'Aceptar',
          openApp: true,
        });
        setOpen(res.value);
      } else {
        setMessage({
          title: 'Pago Realizado con Éxito',
          body: 'Hemos procesado tu pago correctamente, bienvenido a Vetlook',
          btn: 'Aceptar',
          openApp: true,
        });
        setOpen(res.value);
      }
    }
  };

  const plans = () => {
    if (user.typeUser === 'usuario') {
      return (
        <>
          <div className='col-lg-6'>
            <PlanCard
              plan={{
                id: 'USUARIO_ESTANDAR_MENSUAL',
                name: 'Estandar Mensual',
                price: 20,
                period: 'MXN/mensual',
                member: user.membership,
                pending: user.pending,
              }}
              getResponse={(res) => getResponse(res)}
            />
          </div>
          <div className='col-lg-6'>
            <PlanCard
              plan={{
                id: 'USUARIO_ESTANDAR_ANUAL',
                name: 'Estandar Anual',
                price: 180,
                period: 'MXN/anual',
                member: user.membership,
                pending: user.pending,
              }}
              getResponse={(res) => {
                getResponse(res);
              }}
            />
          </div>
        </>
      );
    } else if (user.typeUser === 'negocio' || user.typeUser === 'estetica') {
      return (
        <>
          <div className='col-lg-6'>
            <PlanCardClients
              plan={{
                id: 'CLIENTE_ESTANDAR_MENSUAL',
                name: 'Estandar Mensual',
                price: 200,
                period: 'MXN/mensual',
                member: user.membership,
                pending: user.pending,
              }}
              getResponse={(res) => getResponse(res)}
            />
          </div>
          <div className='col-lg-6'>
            <PlanCardClients
              plan={{
                id: clientPlanName,
                name: 'Preventa Anual',
                price: 1000,
                period: 'MXN/anual',
                member: user.membership,
                pending: user.pending,
              }}
              getResponse={(res) => getResponse(res)}
            />
          </div>
        </>
      );
    } else {
      return;
    }
  };

  const payGateway = () => {
    if (method === 'oxxo') {
      return (
        <Oxxo
          plan={{
            id: plan.plan,
            price: plan.price,
            period: plan.period,
            tipoUsuario: user.typeUser,
          }}
          paymentResponse={(res) => paymentResponse(res)}
        />
      );
    } else if (method === 'creditCard') {
      return (
        <CreditDebitCard
          plan={{
            id: plan.plan,
            price: plan.price,
            period: plan.period,
            typeUser: user.typeUser,
            email: user.email,
          }}
          paymentResponse={(res) => paymentResponse(res)}
        />
      );
    } else {
      return;
    }
  };

  return (
    <div>
      <section className='pricing py-5'>
        <div className='container mb-5'>
          <div className='row'>
            <div className='col-12'>
              <div className='container mb-lg-4 text-center'>
                <h2>
                  {user.membership
                    ? '¡Ya cuentas con membresía!'
                    : '¡Elige tu plan!'}
                </h2>
                <div className='mt-3'>
                  <div className='lead'>{planTitle()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>{plans()}</div>
        </div>
        <div className='container my-5'>
          <div className='row'>
            <div className='col-1 col-lg-2 col-md-0 col-sm-0'></div>
            <div className='col-10 col-lg-8 col-md-12 col-sm-12'>
              {showForm && (
                <div id='method' ref={myRef}>
                  <h3 className='py-3'>
                    Por favor selecciona un método de pago
                  </h3>
                  <PaymentForm getMethod={(res) => getMethod(res)} />
                  {payGateway()}
                </div>
              )}
            </div>
            <div className='col-1 col-lg-2 col-md-0 col-sm-0'></div>
          </div>
        </div>
        <div className='container py-4 text-justify'>
          <small>
            * Las funciones de "mascotas pérdidas", "reporte abuso animal" y
            "adopción de mascotas" se encuentran en desarrollo y estarán
            disponibles pronto.
          </small>
        </div>
      </section>
      <Popup
        open={open}
        close={() => {
          setOpen(!open);
        }}
        title={message.title}
        body={message.body}
        btn={message.btn}
        openApp={message.openApp}
      />
    </div>
  );
};

export default withRouter(Pricing);
