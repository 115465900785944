import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'shards-react';
import successIcon from '../../assets/images/success-icon.png';
import failedIcon from '../../assets/images/failed-icon-min.png';
import '../../assets/scss/Modal.scss';
import { withRouter } from 'react-router-dom';

const Popup = ({
  open = false,
  close = () => {},
  title = 'Vetlook | Diseñada para ti que amas a tu mascota',
  body = 'Bienvenid@',
  btn = 'Cerrar',
  openApp = false,
  history,
}) => {
  const goToApp = () => {
    close();
    window.location.reload();
  };
  return (
    <div>
      <Modal
        className='successModal text-center'
        open={open}
        centered={true}
        size='lg'
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <div>
            <img
              src={openApp === true ? successIcon : failedIcon}
              alt='Notification Icon'
            />
            <div className='container my-5'>
              <h4>{body}</h4>
            </div>
          </div>
          {openApp === false ? (
            <Button className='btn btn-danger' onClick={close}>
              {btn}
            </Button>
          ) : (
            <Button className='btn btn-success' onClick={goToApp}>
              {btn}
            </Button>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};
export default withRouter(Popup);
