import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
} from 'shards-react';
import moment from 'moment';
import 'moment/locale/es-mx';
import userLogo from '../../assets/images/user-default.png';
import alertIcon from '../../assets/images/alert.png';
import '../../assets/scss/UserProfile.scss';
import emailIcon from '../../assets/images/email-icon.png';
import facebookIcon from '../../assets/images/facebook-icon.png';
import googleIcon from '../../assets/images/google-icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { cancellSubscription } from '../../redux/payDucks';

const UserDetails = (props) => {
  // console.log('props', props);
  const dispatch = useDispatch();
  const subID = useSelector((store) => store.user.user.subscription_id);
  const expDate = useSelector((store) => store.user.user.expiration_date);
  const msg = useSelector((store) => store.pay.message);

  const [photo, setPhoto] = React.useState('');
  const [providerIcon, setProviderIcon] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [agree, setAgree] = React.useState(false);
  const [reason, setReason] = React.useState('');
  const [comments, setComments] = React.useState('');
  const [error, setError] = React.useState('');
  // eslint-disable-next-line
  const [message, setMessage] = React.useState('');

  React.useEffect(() => {
    setMessage(msg);
  }, [msg]);

  React.useEffect(() => {
    const profilePicture = () => {
      if (
        props.user.photoURL === undefined ||
        props.user.photoURL === false ||
        props.user.photoURL === null ||
        props.user.photoURL === '/assets/placeholder.jpg'
      ) {
        setPhoto(userLogo);
      } else {
        setPhoto(props.user.photoURL);
      }
    };
    profilePicture();
    const provider = () => {
      if (props.user.providerData === 'google.com') {
        setProviderIcon(googleIcon);
      } else if (props.user.providerData === 'facebook.com') {
        setProviderIcon(facebookIcon);
      } else {
        setProviderIcon(emailIcon);
      }
    };
    provider();
  }, [photo, props.user]);

  const cancellReason = [
    'No me agrado el servicio',
    'Se me hace muy caro',
    'No quiero usar mi tarjeta',
    'Prefiero no decir',
  ];

  const cancelSuscriptionPopup = () => {
    return (
      <Modal
        className='successModal text-center'
        open={open}
        centered={true}
        size='lg'
      >
        <div className='row'>
          <ModalHeader className='col-11 col-lg-9 col-md-9 col-sm-11'>
            ¿Segura/o que quieres continuar?{' '}
            <span role='img' aria-label='crying-face'>
              😢
            </span>
          </ModalHeader>
          <div className='col-1 col-lg-3 col-md-3 col-sm-1'>
            <button
              className='btn btn-danger btn-sm float-right mr-3 my-3'
              type='button'
              onClick={() => setOpen(!open)}
            >
              X
            </button>
          </div>
        </div>
        <ModalBody>
          <form>
            <img src={alertIcon} alt='Notification Icon' />
            <div className='container my-2'>
              <h4>¿Por qué motivo cancelas tu renovación automática?</h4>
              <div className='text-justify py-3'>
                {cancellReason.map((item, index) => {
                  return (
                    <div class='form-check' key={index}>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='cancelReason'
                        id={'option-' + index}
                        value={item}
                        onChange={() => {
                          setReason(item);
                          setError('');
                        }}
                      />
                      <label
                        class='form-check-label'
                        htmlFor={'option-' + index}
                      >
                        {item}
                      </label>
                    </div>
                  );
                })}
                <div class='form-group pt-3'>
                  <label for='comentaries'>
                    ¿Qué podemos hacer para mejorar nuestro servicio?
                  </label>
                  <textarea
                    class='form-control'
                    id='comentaries'
                    rows='3'
                    style={{ resize: 'none' }}
                    onChange={(e) => setComments(e.target.value)}
                  >
                    {comments}
                  </textarea>
                </div>
                <div class='form-check'>
                  <input
                    class='form-check-input'
                    type='checkbox'
                    id='cancellationAgree'
                    onChange={() => setAgree(!agree)}
                  />
                  <label class='form-check-label' for='cancellationAgree'>
                    Confirmo que no podré renovar mi suscripción hasta el{' '}
                    {moment(expDate).format('dddd DD [de] MMMM [del] YYYY')}
                  </label>
                </div>
              </div>
            </div>
            {error && <div className='alert alert-warning'>{error}</div>}
            {agree && (
              <Button
                className='btn btn-warning'
                onClick={() => {
                  if (reason === '') {
                    setError('Por favor selecciona un motivo de cancelación.');
                  } else {
                    dispatch(
                      cancellSubscription({
                        reason,
                        comments,
                        subscriptionId: subID,
                      })
                    );
                  }
                }}
              >
                Cancelar Renovación
              </Button>
            )}
          </form>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div>
      <Card small className='mb-4 pt-3'>
        <CardHeader className='border-bottom text-center'>
          <div className='mb-3 mx-auto'>
            <img
              className='rounded-circle user-img'
              src={photo}
              alt='user profile'
            />
            <div class='providerContainer'>
              <img className='providerIcon' src={providerIcon} alt='provider' />
            </div>
          </div>
          <h4 className='mb-0'>
            {props.user.name} {props.user.lastName}
          </h4>
          <p className='text-muted d-block mt-1 mb-2'>
            Usuario Vetlook desde{' '}
            {moment(props.user.createdAt, 'YYYYMMDDHHmmss').fromNow()}
          </p>
        </CardHeader>
        <ListGroup flush className='text-center'>
          <ListGroupItem>
            <div>
              <strong className='d-block mb-2'>
                {!props.user.membership ? (
                  <span className='danger'>No cuenta con membresía</span>
                ) : (
                  <div>
                    <p>
                      Cuentas con <span className='success'>Membresía</span>{' '}
                      hasta el{' '}
                      {moment(props.user.expiration_date).format(
                        'dddd DD [de] MMMM [del] YYYY'
                      )}
                    </p>
                  </div>
                )}
              </strong>
            </div>
            <strong className='text-muted d-block mb-1'>
              {props.user.typeUser === 'usuario' ? (
                props.user.freeMonth ? (
                  <p>
                    Cuentas con un <span className='success'>mes gratis</span>{' '}
                    hasta el{' '}
                    {moment(props.user.freeMonthExpiration).format(
                      'dddd DD [de] MMMM [del] YYYY'
                    )}
                  </p>
                ) : props.user.membership ? (
                  <p>
                    Gracias por usar <span className='success'>Vetlook</span>{' '}
                  </p>
                ) : (
                  <p>Considera elegir uno de nuestros planes.</p>
                )
              ) : props.user.typeUser === 'negocio' ? (
                props.user.membership ? (
                  <p>
                    Gracias por usar <span className='success'>Vetlook</span>{' '}
                  </p>
                ) : (
                  <p>Considera elegir uno de nuestros planes.</p>
                )
              ) : null}
            </strong>
            {props.user.paymentMethod === 'tarjeta' &&
            props.user.membership &&
            !props.user.membershipCancelled &&
            props.user.preSaleUser !== 'CLIENTE_ANUAL_PREVENTA' ? (
              <>
                <span>
                  Renovación automática{' '}
                  <strong className='text-success'>activada</strong>.
                </span>
                <button
                  className='btn btn-link'
                  type='button'
                  onClick={() => setOpen(!open)}
                >
                  ¿Deseas cancelar la renovación automática?
                </button>
              </>
            ) : props.user.membershipCancelled ||
              props.user.preSaleUser === 'CLIENTE_ANUAL_PREVENTA' ? (
              <p>
                {/* Tu membresía fue cancelada el{' '}
                  {moment(props.user.cancelled_date, 'YYYYMMDDHHmmss').format(
                    'dddd DD [de] MMMM [del] YYYY'
                  )} */}
                Renovación automática{' '}
                <strong className='text-warning'>desactivada</strong>.
              </p>
            ) : null}
            <p>
              Fecha Actual: {moment().format('dddd DD [de] MMMM [del] YYYY')}
            </p>
          </ListGroupItem>
        </ListGroup>
      </Card>
      {cancelSuscriptionPopup()}
    </div>
  );
};

export default UserDetails;
