import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'shards-react';

import SidebarMainNavbar from './SidebarMainNavbar';
import SidebarNavItems from './SidebarNavItems';

import '../../../assets/scss/Sidebar.scss';

import { Store } from '../../../flux';

function MainSidebar(props) {
  const getState = () => {
    return {
      menuVisible: false,
      sidebarNavItems: Store.getSidebarItems(),
    };
  };

  React.useEffect(() => {
    Store.addChangeListener(onChange);
    return () => {
      Store.removeChangeListener(onChange);
    };
  }, []);

  const onChange = () => {
    getState({
      ...getState,
      menuVisible: Store.getMenuState(),
      sidebarNavItems: Store.getSidebarItems(),
    });
  };

  const classes = classNames(
    'main-sidebar',
    'px-0',
    'col-12',
    getState.menuVisible && 'open'
  );

  return (
    <Col
      tag='aside'
      className={classes}
      lg={{ size: 2 }}
      md={{ size: 3 }}
      sm={{ size: 3 }}
    >
      <SidebarMainNavbar hideLogoText={props.hideLogoText} />
      <SidebarNavItems />
    </Col>
  );
}

MainSidebar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool,
};

MainSidebar.defaultProps = {
  hideLogoText: false,
};

export default MainSidebar;
