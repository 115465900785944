import React from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import routes from './routes';
import { auth, db } from './firebase';
import './assets/styles/shards-dashboards.1.1.0.css';
import Loader from './components/Loader';
import { LoginLayout } from './layouts';
import { useSelector } from 'react-redux';

const App = () => {
  const [firebaseUser, setFirebaseUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [member, setMember] = React.useState(null);

  const uid = useSelector((store) => store.user.uid);

  React.useEffect(() => {
    const fetchUser = () => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          setFirebaseUser(user);
        } else {
          setFirebaseUser(null);
        }
      });
    };
    const timer = setTimeout(() => {
      fetchUser();
      setLoading(!loading);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    const checkMembership = async () => {
      try {
        const document = await db
          .collection('USUARIOS')
          .doc(uid)
          .get();
        const object = document.data();
        if (object.membership === false) {
          setMember(false);
        } else {
          setMember(true);
        }
      } catch (error) {}
    };
    checkMembership();
  }, [uid, member]);

  if (loading) {
    return <Loader />;
  } else if (firebaseUser && member === false) {
    return (
      <Router basename={process.env.REACT_APP_BASENAME || ''}>
        <Redirect from='/' to='/payment/pricing' />
        <Redirect from='/payment/login' to='/payment/pricing' />
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={(props) => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                );
              }}
            />
          );
        })}
      </Router>
    );
  } else if (firebaseUser && member === true) {
    return (
      <Router basename={process.env.REACT_APP_BASENAME || ''}>
        <Redirect from='/' to='/payment/my-account' />
        <Redirect from='/payment/login' to='/payment/my-account' />
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={(props) => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                );
              }}
            />
          );
        })}
      </Router>
    );
  } else if (firebaseUser) {
    return (
      <Router basename={process.env.REACT_APP_BASENAME || ''}>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={(props) => {
                return (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                );
              }}
            />
          );
        })}
      </Router>
    );
  } else {
    return (
      <Router>
        <Redirect from='/' to='/payment/login' />
        <div>
          <Route
            path='/payment/login'
            exact={true}
            render={() => <LoginLayout />}
          />
        </div>
      </Router>
    );
  }
};
export default App;
